/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';

function SEO({
  description,
  lang,
  meta,
  title,
  reviews,
  courses,
  province,
  city,
}) {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            url
            defaultTitle: title
            defaultDescription: description
            headline
            siteLanguage
            ogLanguage
            author
            twitter
            facebook
          }
        }
      }
    `
  );

  const { locale } = useLocalization();

  const lang2 = locale === 'ca' ? 'en' : locale;
  // const { t } = useTranslation();

  let metaDescription = description || site.siteMetadata.defaultDescription;

  metaDescription = city
    ? `React Academy course in ${city}, ${province}. ` + metaDescription
    : metaDescription;

  title = title === 'React Academy' ? null : title;
  let defaultTitle =
    lang2 === 'en' ? site.siteMetadata.defaultTitle : 'Académie React';

  // const webPageStructure = {
  //   '@context': 'https://schema.org/',
  //   '@type': 'WebPage',
  //   name: 'React Academy, training courses in Canada',
  //   speakable: {
  //     '@type': 'SpeakableSpecification',
  //     xpath: [
  //       '/html/head/title',
  //       "/html/head/meta[@name='description']/@content",
  //     ],
  //   },
  //   url: 'https://www.reactAcademy.ca/',
  // };

  const reviewStructure = {
    '@context': 'http://schema.org',
    '@type': 'Review',
    name: 'React Academy, training courses in Canada',
    url: 'https://www.reactAcademy.ca',
    image: 'https://www.reactAcademy.ca/image.svg',
    author: 'Thomas Lee',
    reviewBody:
      "This is the best training I've had in years! The trainer is passionate about the technology, and it shows.",
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4.8',
    },
    itemReviewed: {
      '@type': 'LocalBusiness',
      name: 'React Academy Course (workshop training)',
      priceRange: '1495',
      image: 'https://www.reactAcademy.ca/image.svg',
      address: {
        '@type': 'PostalAddress',
        addressCountry: {
          '@type': 'Country',
          name: 'Canada',
        },
      },
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: lang2,
      }}
      title={title}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}

      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: 'React Academy',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://www.ReactAcademy.ca/ReactAcademOg.png`,
        },
        {
          property: `og:url`,
          content: `https://www.ReactAcademy.ca/`,
        },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: 'React Academy',
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={[
        {},
        // {
        //   type: 'application/ld+json',
        //   innerHTML: JSON.stringify(webPageStructure),
        // },
        reviews
          ? {
              type: 'application/ld+json',
              innerHTML: JSON.stringify(reviewStructure),
            }
          : {},
      ]}
    ></Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  reviews: false,
  courses: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
