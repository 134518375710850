import React from 'react';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  //faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

export default (props) => {
  React.useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    function bottomEvent(entries, obs) {
      if (entries[0].isIntersecting) {
        window.gtag &&
          window.gtag('event', 'interact', {
            event_category: 'Scroll to section',
            event_action: 'Scrolled to bottom',
            event_label: 'Scrolled to bottom',
          });
        obs.disconnect();
      }
    }

    const observer = new IntersectionObserver(bottomEvent, options);
    observer.observe(document.querySelector('footer#footer'));
  }, []);
  const { t } = useTranslation();

  return (
    <footer id='footer' className='py-4 bg-primary text-light text-center'>
      <Container>
        <h2 className='text-center display-4'>
          <a
            href='https://www.facebook.com/ReactAcademyLive'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon
              icon={faFacebookSquare}
              // size="2x"
              className='text-light'
              title='Facebook'
            />
          </a>{' '}
          <a
            href='https://www.linkedin.com/company/reactacademy'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              // size="2x"
              className='text-light'
              title='LinkedIn'
            />
          </a>{' '}
          <a
            href='https://twitter.com/ReactAcademy1'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon
              icon={faTwitterSquare}
              // size="2x"
              className='text-light'
              title='Twitter'
            />
          </a>
        </h2>
        <p className=''>
          © {new Date().getFullYear()}, {t('built-with')}
          {` `}
          <a href='https://www.gatsbyjs.org' className='text-light'>
            Gatsby
          </a>
        </p>
      </Container>
    </footer>
  );
};
