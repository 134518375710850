import React from 'react';

import { LocalizedLink as Link } from 'gatsby-theme-i18n';

export default function LanguageLink(props) {
  function click(evt) {
    if (props.language) {
      localStorage.setItem('lang', props.language);
    }
    if (props.onClose) {
      evt.preventDefault();
      props.onClose();
    }
  }

  return <Link {...props} onClick={click} />;
}
