import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import Logo from '../images/logoReact.svg';
import En from '../images/us.svg';
import Fr from '../images/fr.svg';
import Ca from '../images/ca.svg';

import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import { useLocation } from '@reach/router';
import LanguageLink from './LanguageLink';
//import { usePageContext, useTranslation, Link } from '@3nvi/gatsby-theme-intl';

//import logo from '../images/oldLogoReact.svg';
//import { globalHistory } from '@reach/router';
//import ToggleMenu from './toggle-menu';

export default function Menu(props) {
  const [isOpen, setOpen] = useState(false);

  //use page context:
  // originalPath: "/"
  // lang: "en"
  // supportedLanguages: ["en", "fr"]
  // defaultLanguage: "en"
  // siteUrl: "localhost:8080"

  const { locale, defaultLang } = useLocalization();
  const location = useLocation();

  let originalPath = location.pathname;
  if (locale !== defaultLang) {
    const [, , ...rest] = originalPath.split('/');
    originalPath = `/${rest.join('/')}`;
  }

  const { t } = useTranslation();

  //toggle the menu visibility when we have a hamburger menu
  function toggle() {
    setOpen(!isOpen);
  }

  // console.log(props.inlineMenu);

  return (
    <Navbar className='fixed-top' color='primary' dark expand='md'>
      <NavbarBrand style={{ fontFamily: 'Days One' }} href='/'>
        <Logo height='34' className='mr-3' />
        {t('react-academy')}
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className='ml-auto' navbar>
          <NavItem>
            <NavLink
              href={`https://www.reactAcademy.live/${
                locale === defaultLang ? '' : locale
              }`}
            >
              {t('home')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href={`https://www.reactAcademy.ca/${
                locale === defaultLang ? '' : locale + '/'
              }handouts`}
            >
              {t('handouts')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='/' active>
              {t('photo-gallery')}
            </NavLink>
          </NavItem>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {t('other-training')}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href='https://www.AngularAcademy.ca/'>
                Angular Academy
              </DropdownItem>
              <DropdownItem href='https://www.VueAcademy.ca/'>
                Vue Academy
              </DropdownItem>
              <DropdownItem href='https://www.KubernetesAcademy.ca'>
                Kubernetes Academy
              </DropdownItem>
              <DropdownItem href='https://www.AzureAcademy.ca'>
                Azure Academy
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href='https://www.Coding-Academy.ca'>
                Coding Academy
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {locale === 'en' ? (
                <En height='34' />
              ) : locale === 'ca' ? (
                <Ca height='34' />
              ) : (
                <Fr height='34' />
              )}
            </DropdownToggle>
            <DropdownMenu right>
              {locale !== 'en' && (
                <DropdownItem
                  tag={LanguageLink}
                  to={originalPath}
                  language={'en'}
                >
                  <En height='34' className='mr-3' />
                  US and World
                </DropdownItem>
              )}
              {locale !== 'ca' && (
                <DropdownItem
                  tag={LanguageLink}
                  to={originalPath}
                  language={'ca'}
                >
                  <Ca height='34' className='mr-3' />
                  Canada English
                </DropdownItem>
              )}
              {locale !== 'fr' && (
                <DropdownItem
                  tag={LanguageLink}
                  to={originalPath}
                  language={'fr'}
                >
                  <Fr height='34' className='mr-3' />
                  Français Canada
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
